import React from "react"
import PropTypes from "prop-types"
import Header from "./header"
import Footer from "./footer"

const Layout = ({
  children,
  width,
  backgroundImage,
  backgroundImageStyles,
  coverText,
  bottomBannerBar,
}) => {
  return (
    <>
      <Header
        width={width}
        backgroundImage={backgroundImage}
        coverText={coverText}
        bottomBannerBar={bottomBannerBar}
        backgroundImageStyles={backgroundImageStyles}
      />
      <main>{children}</main>
      <Footer width={width}></Footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  bigHeader: PropTypes.bool,
  width: PropTypes.number.isRequired,
}

Layout.defaultProps = {
  width: 0,
  bottomBannerBar: true,
}

export default Layout
