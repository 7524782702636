import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

const query = graphql`
  {
    site {
      siteMetadata {
        author
        siteDesc: description
        siteUrl: url
        siteTitle: title
      }
    }
  }
`

const SEO = ({ title, description }) => {
  const { site } = useStaticQuery(query)
  const { siteDesc, siteTitle, siteUrl } = site.siteMetadata

  return (
    <Helmet
      defer={false}
      htmlAttributes={{ lang: "nl" }}
      siteUrl={siteUrl}
      title={`${title}${siteTitle}`}>
      <meta name="description" content={description || siteDesc} />
      <meta name="theme-color" content="#C1B299"></meta>
      <link
        rel="stylesheet"
        href="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.0/css/bootstrap.min.css"
        integrity="sha384-9aIt2nRpC12Uk9gS9baDl411NQApFmC26EwAOH8WgZl5MYYxFfc+NcPb1dKGj7Sk"
        crossorigin="anonymous"
      />
    </Helmet>
  )
}

export default SEO
