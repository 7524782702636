import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { Button, Container, Row, Col } from "react-bootstrap"
import BackgroundImage from "gatsby-background-image"
import { FaCheck } from "react-icons/fa"
import Fade from 'react-reveal/Fade';

function Header({ width, backgroundImage, backgroundImageStyles, coverText, bottomBannerBar }) {
  const data = useStaticQuery(graphql`
    query headerQuery {
      Logo: file(relativePath: { eq: "HOME/LOSSE AFBEELDINGEN/Logo_Home.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
      }
    }
  `)

  const hamburgerClick = () => {
    const hamburger = document.querySelector(".hamburger")
    const contactForm = document.querySelector(".contactForm")
    const navLinks = document.querySelector(".nav-links")
    const links = document.querySelectorAll(".nav-links li")
    let navOverlay = document.getElementById("nav-overlay");
    hamburger.classList.toggle("is-active")
    if (contactForm != undefined || contactForm != null) {
      contactForm.classList.toggle("move-away")
    }
    navLinks.classList.toggle("open")
    links.forEach(link => {
      link.classList.toggle("fade")
    })
    if (navOverlay.style.zIndex == 5){
      navOverlay.style.zIndex = -1;
    } else {
      navOverlay.style.zIndex = 5;
    }
  }

  let bottomBannerBarStyling = {backgroundColor: "#1F2D53", color: "white"}

  if (width > 1750) {
    bottomBannerBarStyling = {backgroundColor: "#1F2D53", color: "white", padding: "0 15vw 0 15vw"}
  }

  const bottomBar = bottomBannerBar ? <Container fluid>
    <Row className="head-text text-center" style={bottomBannerBarStyling}>
      <Col xs={12} xl={3} className="py-3 py-xl-4" style={{height: "100%", display: "flex", alignItems: "center", justifyContent: "center"}}>
        <FaCheck size="1.25em" className="mr-4 mb-1"/>
        <h5>BETROUWBAAR</h5>
      </Col>
      <Col xs={12} xl={3} className="py-3 py-xl-4" style={{height: "100%", display: "flex", alignItems: "center", justifyContent: "center"}}>
      <FaCheck size="1.25em" className="mr-4 mb-1"/>
        <h5>ERVAREN</h5>
      </Col>
      <Col xs={12} xl={3} className="py-3 py-xl-4" style={{height: "100%", display: "flex", alignItems: "center", justifyContent: "center"}}>
      <FaCheck size="1.25em" className="mr-4 mb-1"/>
        <h5>VEELZIJDIG</h5>
      </Col>
      <Col xs={12} xl={3} className="py-3 py-xl-4" style={{height: "100%", display: "flex", alignItems: "center", justifyContent: "center"}}>
      <FaCheck size="1.25em" className="mr-4 ml-0 ml-xl-n4 mb-1"/>
        <h5>WAAR&nbsp;VOOR&nbsp;UW&nbsp;GELD</h5>
      </Col>
    </Row>
  </Container> : ""

  const bigCoverPage = <header>
      <BackgroundImage className="parallax" fluid={backgroundImage} style={backgroundImageStyles} loading="eager">
        <nav className={"text-center"}>
          <ul className={"nav nav-fill"}>
            <Link to="/"><li className={"ml-n3"} style={{width:"25em", textDecoration: "none"}}><Image fluid={data.Logo.childImageSharp.fluid}/></li></Link>
          </ul>
          <ul className={"nav nav-fill justify-content-between"} style={{marginTop: "-11em", marginLeft: "35vw"}}>
            <li className={"nav-item"}><Link style={{ textDecoration: "none", color: "white" }} className={"head-text"} to="/">Home</Link></li>
            <li className={"nav-item"}><Link style={{ textDecoration: "none", color: "white" }} className={"head-text"} to="/about/">Over ons</Link></li>
            <li className={"nav-item"}><Link style={{ textDecoration: "none", color: "white" }} className={"head-text"} to="/workMethod/">Werkwijze</Link></li>
            <li className={"nav-item"}><Link style={{ textDecoration: "none", color: "white" }} className={"head-text"} to="/activities/">Werkzaamheden</Link></li>
            <li className={"nav-item"}><Link style={{ textDecoration: "none", color: "white" }} className={"head-text"} to="/contact/">Contact</Link></li>
            <li className={"nav-item"}><Button href="tel:+31-629543404" rel="nofollow" className={"head-text no-underline"} style={{backgroundColor:"#E52E12", borderRadius: 0, display:"inline-block", marginTop:"-6px", border: 0}}>Bel direct</Button></li>
          </ul>
        </nav>
        <div>
          {coverText}
        </div>
        <div className="scrollArrow">
          <div className="chevron"/>
          <div className="chevron"/>
          <div className="chevron"/>
        </div>
        </BackgroundImage>
        {bottomBar}
</header>

const smallCoverPage = <header>
<BackgroundImage className="parallax" fluid={backgroundImage} style={backgroundImageStyles} loading="eager">
  <nav id={"nav-overlay"} className={"text-center"} style={{position:"relative", zIndex:-1, transition: "z-index 3s ease, width 3s ease"}}>
    <Link to="/" style={{width:"12em", left: 0, position:"absolute", zIndex:1, textDecoration: "none"}}><Image fluid={data.Logo.childImageSharp.fluid}/></Link>
    <button className="hamburger hamburger--collapse" type="button" tabIndex={0} onClick={hamburgerClick} onKeyDown="">
      <span className="hamburger-box">
        <span className="hamburger-inner"/>
      </span>
    </button>
    <ul className={"nav nav-fill nav-links"}>
      <li className={"nav-item"}><Link style={{ textDecoration: "none", color: "white", marginTop:"25vh" }} className={"head-text"} to="/">Home</Link></li>
      <li className={"nav-item"}><Link style={{ textDecoration: "none", color: "white" }} className={"head-text"} to="/about/">Over ons</Link></li>
      <li className={"nav-item"}><Link style={{ textDecoration: "none", color: "white" }} className={"head-text"} to="/workMethod/">Werkwijze</Link></li>
      <li className={"nav-item"}><Link style={{ textDecoration: "none", color: "white" }} className={"head-text"} to="/activities/">Werkzaamheden</Link></li>
      <li className={"nav-item"}><Link style={{ textDecoration: "none", color: "white" }} className={"head-text"} to="/contact/">Contact</Link></li>
      <li className={"nav-item"}><Button href="tel:+31-629543404" rel="nofollow" className={"head-text no-underline"} style={{backgroundColor:"#E52E12", borderRadius:0, border: 0, display:"inline-block", marginTop:"-6px"}}>Bel direct</Button></li>
    </ul>
  </nav>
  <div>
    {coverText}
  </div>
  <div className="centeredCTA">
    <Fade right>
      <Button href="tel:+31-629543404" rel="nofollow" className={"head-text no-underline"} style={{backgroundColor:"#E52E12", borderRadius:10, border: 0, display:"inline-block", marginTop: "25px"}}>Bel direct</Button>
    </Fade>
  </div>
  <div className="scrollArrow">
    <div className="chevron"/>
    <div className="chevron"/>
    <div className="chevron"/>
  </div>
  </BackgroundImage>
  {bottomBar}
</header>

  if (width > 1250) {
    return bigCoverPage
  } else {
    return smallCoverPage
  }
}

Header.propTypes = {
  width: PropTypes.number.isRequired,
  backgroundImage: PropTypes.any.isRequired,
  coverText: PropTypes.any.isRequired,
}

Header.defaultProps = {
  width: 0,
  backgroundImageStyles: {height: "100vh"}
}

export default Header
