import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import { FaFacebook } from "react-icons/fa"

function Footer({ width }) {
  const bigFooter = (
    <Container as="footer" fluid style={{ height: "300px", color: "#142D56", backgroundColor: "#fafafa" }}>
      <Row className="px-5 py-5">
        <Col className="mb-5 " xs={{ span: 12 }}>
          <Row>
            <Col>
              <h5 className="head-text mb-4"><Link rel="nofollow" className="underline-animation" style={{ textDecoration: "none", color: "#142D56" }} to="/activities/">WERKZAAMHEDEN</Link></h5>
              <h5 className="normal-text mb-4"><Link rel="nofollow" className="underline-animation" style={{ textDecoration: "none", color: "#142D56" }} to="/activities/">Dakwerkzaamheden</Link></h5>
              <h5 className="normal-text mb-4"><Link rel="nofollow" className="underline-animation" style={{ textDecoration: "none", color: "#142D56" }} to="/activities/">Schilderwerk</Link></h5>
              <h5 className="normal-text mb-4"><Link rel="nofollow" className="underline-animation" style={{ textDecoration: "none", color: "#142D56" }} to="/activities/">Stucwerk</Link></h5>
              <h5 className="normal-text"><Link rel="nofollow" className="underline-animation" style={{ textDecoration: "none", color: "#142D56" }} to="/activities/">Timmerwerkzaamheden</Link></h5>
            </Col>
            <Col>
              <h5 className="normal-text mb-4"><Link rel="nofollow" className="underline-animation" style={{ textDecoration: "none", color: "#142D56" }} to="/">Home</Link></h5>
              <h5 className="normal-text mb-4"><Link rel="nofollow" className="underline-animation" style={{ textDecoration: "none", color: "#142D56" }} to="/about/">Over ons</Link></h5>
              <h5 className="normal-text mb-4"><Link rel="nofollow" className="underline-animation" style={{ textDecoration: "none", color: "#142D56" }} to="/workMethod/">Werkwijze</Link></h5>
              <h5 className="normal-text mb-4"><Link rel="nofollow" className="underline-animation" style={{ textDecoration: "none", color: "#142D56" }} to="/activities/">Werkzaamheden</Link></h5>
              <h5 className="normal-text"><Link rel="nofollow" className="underline-animation" style={{ textDecoration: "none", color: "#142D56" }} to="/contact/">Contact</Link></h5>
            </Col>
            <Col>
                <h5 className="normal-text mb-4">Regio Den Bosch <a href="tel:+31 73 23 40 811" style={{ textDecoration: "none", color: "#142D56", fontWeight: "bold" }} rel="nofollow">T. 073-23 40 811</a></h5>
            </Col>
            <Col xs={{ span: 4 }} style={{ textAlign: "right" }}>
              <h5 className="head-text mb-4">CONTACTGEGEVENS</h5>
              <h5 className="head-text mb-4">
                Alders Vastgoedonderhoud<br/>Buitenpepersdreef 55
                <br/>5231 AB ‘s-Hertogenbosch
              </h5>
              <h5 className="head-text mb-4">
                E. <a href="mailto:info@aldersvastgoedonderhoud.nl" style={{ textDecoration: "none", color: "#142D56" }} rel="nofollow">info@aldersvastgoedonderhoud.nl</a><br/>
                <a href="tel:+31 73 23 40 811" style={{ textDecoration: "none", color: "#142D56" }} rel="nofollow">T. 073-23 40 811</a>
              </h5>
            </Col>
          </Row>
        </Col>
        <Col>
          <h5 className="head-text"><a style={{ textDecoration: "none", color: "#142D56" }} target="_blank" href="https://www.facebook.com/Alders-Vastgoedonderhoud-102338958352020" rel="nofollow noreferrer">VOLG ONS OP</a></h5>
          <h5 className="normal-text">
          <a style={{ textDecoration: "none", color: "#142D56" }} target="_blank" href="https://www.facebook.com/Alders-Vastgoedonderhoud-102338958352020" rel="nofollow noreferrer"><FaFacebook color="#142D56" size="1.5em" /> Facebook</a>
          </h5>
        </Col>
      </Row>
      <Row style={{height: "40%", backgroundColor: "#142D56"}}/>
    </Container>
  )

  const smallFooter = (
    <Container as="footer" fluid style={{ height: "300px", color: "#142D56", textAlign:"center", backgroundColor: "#fafafa" }}>
      <Row className="py-5">
        <Col className="mb-5 " xs={{ span: 12 }}>
          <Row>
            <Col xs={{span: 12, order: 1}} sm={{span:6}}>
              <h5 className="head-text mb-4"><Link rel="nofollow" className="underline-animation" style={{ textDecoration: "none", color: "#142D56" }} to="/activities/">WERKZAAMHEDEN</Link></h5>
              <h5 className="normal-text mb-4"><Link rel="nofollow" className="underline-animation" style={{ textDecoration: "none", color: "#142D56" }} to="/activities/">Dakwerkzaamheden</Link></h5>
              <h5 className="normal-text mb-4"><Link rel="nofollow" className="underline-animation" style={{ textDecoration: "none", color: "#142D56" }} to="/activities/">Schilderwerk</Link></h5>
              <h5 className="normal-text mb-4"><Link rel="nofollow" className="underline-animation" style={{ textDecoration: "none", color: "#142D56" }} to="/activities/">Stucwerk</Link></h5>
              <h5 className="normal-text"><Link rel="nofollow" className="underline-animation" style={{ textDecoration: "none", color: "#142D56" }} to="/activities/">Timmer&shy;werkzaamheden</Link></h5>
            </Col>
            <Col xs={{order: 0}} className="mb-md-0 mb-5">
              <h5 className="normal-text mb-4"><Link rel="nofollow" className="underline-animation" style={{ textDecoration: "none", color: "#142D56" }} to="/">Home</Link></h5>
              <h5 className="normal-text mb-4"><Link rel="nofollow" className="underline-animation" style={{ textDecoration: "none", color: "#142D56" }} to="/about/">Over ons</Link></h5>
              <h5 className="normal-text mb-4"><Link rel="nofollow" className="underline-animation" style={{ textDecoration: "none", color: "#142D56" }} to="/workMethod/">Werkwijze</Link></h5>
              <h5 className="normal-text mb-4"><Link rel="nofollow" className="underline-animation" style={{ textDecoration: "none", color: "#142D56" }} to="/activities/">Werkzaamheden</Link></h5>
              <h5 className="normal-text"><Link rel="nofollow" className="underline-animation" style={{ textDecoration: "none", color: "#142D56" }} to="/contact/">Contact</Link></h5>
            </Col>
            <Col xs={{span: 12, order: 3}} sm={{span:6, order: 2}} className="mt-5">
                <p className="normal-text mb-4">Regio Den Bosch <a href="tel:+31 73 23 40 811" style={{ textDecoration: "none", color: "#142D56", fontWeight: "bold" }} rel="nofollow">T. 073-23 40 811</a></p>
            </Col>
            <Col xs={{ order: 2 }} sm={{order: 3}} className="mt-5">
              <h5 className="head-text mb-4">CONTACTGEGEVENS</h5>
              <p className="head-text mb-4">
                Alders Vastgoedonderhoud<br/>Buitenpepersdreef 55
                <br/>5236 WC ‘s-Hertogenbosch
              </p>
              <p className="head-text mb-4">
                E. <a href="mailto:info@aldersvastgoedonderhoud.nl" style={{ textDecoration: "none", color: "#142D56" }} rel="nofollow noreferrer">info&shy;@aldersvastgoedonderhoud.nl</a><br/><a href="tel:+31 73 23 40 811" style={{ textDecoration: "none", color: "#142D56" }} rel="nofollow">T. 073-23 40 811</a>
              </p>
            </Col>
          </Row>
        </Col>
        <Col>
          <h5 className="head-text"><a style={{ textDecoration: "none", color: "#142D56" }} target="_blank" href="https://www.facebook.com/Alders-Vastgoedonderhoud-102338958352020" rel="nofollow noreferrer">VOLG ONS OP</a></h5>
          <h5 className="normal-text">
          <a style={{ textDecoration: "none", color: "#142D56" }} target="_blank" href="https://www.facebook.com/Alders-Vastgoedonderhoud-102338958352020" rel="nofollow noreferrer"><FaFacebook color="#142D56" size="1.5em" /> Facebook</a>
          </h5>
        </Col>
      </Row>
      <Row style={{height: "40%", backgroundColor: "#142D56"}}/>
    </Container>
  )

  if (width > 1200) {
    return bigFooter
  } else {
    return smallFooter
  }
}

Footer.propTypes = {
  width: PropTypes.number.isRequired,
}

Footer.defaultProps = {
  width: 0,
}

export default Footer
